/*
** "Base" theme
*/
.hidden {display:none;}
/* blog */
.shopify-posts .post {margin:0 0 15px;}
.shopify-posts .info-box {overflow:hidden;}
.shopify-posts .btn-more {float:right;}
/* paging */
.paging {
    margin:0 0 15px;
    overflow:hidden;
    text-align:center;
    width:100%;
}
.paging li {
    display:inline;
    list-style-type:none;
    margin:0 10px;    
}
.paging li.active {font-weight:bold;}
/* comments */
#comments {margin:0 0 15px;}
#comment-list {
    margin:0;
    padding:0 0 0 20px;
}
#comment-list li {
    list-style-position:outside;
    margin:0 0 10px;
}
#comment-submit {padding:0 7px;}
/* search-results */
.search-results {
  	padding:0;
  	margin:0;
  	list-style:none;
}
.search-results li {
    margin:0 0 15px;
    list-style:none;
}
.search-results:after,
.search-results li:after {
    display:block;
    content:"";
    clear:both;
}
/* cart */
.shopify-cart,
.shopify-cart table  {width:100%;}
.shopify-cart  .prd,
.shopify-cart  .prc,
.shopify-cart  .rmv,
.shopify-cart  .qty {
    text-align:center;
    width:24%;
    padding-bottom:5px;
}
.shopify-cart  .total  {text-align:right;}
.shopify-cart .img-box img {margin:5px auto;}
.ajax-next {
	display: block;
	cursor: default;
	height: 30px;
	text-indent: -9999px;
	overflow: hidden;
	background:url(loader.gif) no-repeat 50% 50%;
}
.full-load-link {display: none;}
.ajax-holder .filtering-hidden {display: none;}
.select .selector-wrapper label {display: none;}


.shopify-cart table {
	width: 100%;
	border-collapse: collapse;
	font-size: 15px;
	line-height: 17px;
	color: #7f7f7f;
	margin: 0 0 14px;
}

.shopify-cart table th,
.shopify-cart table td {
	border: 0;
	text-align: center;
}

.shopify-cart table th:first-child,
.shopify-cart table td:first-child {
	width: 700px;
	text-align: left;
}

.shopify-cart table th {
	font-size: 18px;
	line-height: 20px;
	font-weight: normal;
	padding: 2px 0 14px;
	color: #666;
	border-bottom: 1px solid #e7e7e7;
}

.shopify-cart table td {
	padding: 29px 0 52px;
	border-bottom: 1px solid #e7e7e7;
}

.shopify-cart table h2 {
	color: #2c7fa6;
	font-size: 20px;
	line-height: 30px;
	margin: 0 0 4px;
}

.shopify-cart table h2 a {
	color: #2c7fa6;
}

.shopify-cart table .by {
	display: block;
	color: #000;
	font-size: 15px;
	margin: 0 0 28px;
}

.shopify-cart table .by a {
	color: #2c7fa6;
}

.shopify-cart table .stock {
	display: block;
	color: #5b820e;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	margin: 0 0 7px;
}

.shopify-cart a {
	text-decoration: none;
}

.shopify-cart a:hover {
	text-decoration: underline;
}

.shopify-cart .image {
	float: left;
	margin: 0 37px 0 20px;
}

.shopify-cart .image img {
	vertical-align: top;
}

.shopify-cart .text-box {
	overflow: hidden;
	padding: 21px 0 3px;
}

.shopify-cart .price {
	font-weight: bold;
	font-size: 22px;
	line-height: 25px;
	color: #171e6d;
	display: block;
}
.shopify-cart .product-image {
	float:left;
	margin:0 10px 0 0;
}
.product-description {
	padding:10px 0 0;
}

.shopify-cart .btn {
	background: #9e1f1a;
	font-size: 18px;
	line-height: 20px;
	color: #fff;
	display: block;
	border: 0;
	padding: 24px 40px;
	font-weight: bold;
  	float:right;
  	margin:0 0 0 20px;
}
.shopify-cart .btn:hover {
	background: #000;
}
.shopify-cart label {
	display:block;
	margin:0 0 10px;
}
.shopify-cart #note {
	width:100%;
	margin-bottom:15px;
}


.shopify-cart .total {
	display: block;
	text-align: right;
	color: #000;
	font-weight: bold;
	font-size: 20px;
	line-height: 23px;
	margin: 0 0 28px;
}

.shopify-cart .text-box {
	overflow: hidden;
	font-size: 15px;
	line-height: 25px;
	color: #a3a3a3;
}

.shopify-cart .text-box a {
	text-decoration: none;
	color: #a3a3a3;
}

.shopify-cart .text-box a:hover {
	text-decoration: underline;
}

.shopify-cart .text-box p {
	margin: 0 0 25px;
}
#customer_login label,
#create_customer label{
	display: block;
	color: #999;
	font-size: 15px;
	line-height: 16px;
	font-weight: 600;
	margin: 0 0 8px;
  	padding:0;
  	float:none;
  	width: auto;
}
.form .row {
	padding: 0 0 26px;
}
.form input[type="email"],
.form input[type="text"],
.form input[type="password"],
#customer_login input[type="email"],
#customer_login input[type="text"],
#customer_login input[type="password"],
#customer_login #login_password input[type="password"],
#create_customer input[type="email"],
#create_customer input[type="text"],
#create_customer input[type="password"]{
	width: 100%;
	margin: 0 0 10px;
	padding: 11px 10px;
	border-radius: 4px;
	background: #f9f9f9;
	border: 1px solid #dadada;
	font-size: 14px;
	line-height: 16px;
	outline: none;
}
#customer_login input[type="email"],
#customer_login input[type="text"],
#customer_login input[type="password"],
#customer_login #login_password input[type="password"],
#create_customer input[type="email"],
#create_customer input[type="text"],
#create_customer input[type="password"]{
	width: 99.5%;
}
.form input[type="submit"],
#customer_login input[type="submit"],
#create_customer input[type="submit"]{
  	text-shadow:none;
  	border-radius:0;
	background: #9e1f1a;
	font-size: 18px;
	line-height: 20px;
	border:0;
	color: #fff;
	width: 244px;
	height: 57px;
	padding: 0;
	outline: none;
	cursor: pointer;
	margin: 4px auto 10px;
	display: block;
  	text-transform:uppercase;
}
.form input[type="submit"]:hover,
#customer_login input[type="submit"]:hover,
#create_customer input[type="submit"]:hover {background:#000;}
.large_form #forgot_password a{
	float: none;
	border: 0;
	padding: 0;
	background: none;
	width: auto;
}
.form .txt {
	display: block;
	text-align: center;
	font-size: 13px;
	padding: 3px 0 0;
}
.large_form{
	text-align: left;
}
#customer_login .action_bottom,
#create_customer .action_bottom{
	padding: 0;
}
#customer_login .action_bottom .note,
#create_customer .action_bottom .note{
	padding-top: 16px;
	display: block;
}
.errors ul {
	list-style:none;
	margin:0;
	padding:10px 0;
}
.customer_address_table  {
	font-size:14px;
	line-height:16px;
}
body #add_address td {
	padding:6px 0;
}
#add_address #address_default_address_new {
	margin:-4px 3px 0 0;
}
#wrapper #customer_sidebar {
	padding:0 0 0 20px;
}
#collections-listing {
	max-width:970px;
	padding:0 15px 20px;
  	margin:0 auto;
}
#collections-listing ul {
	list-style:none;
	margin:0;
	padding:0;
}
#collections-listing ul li {
	list-style:none;
	margin:0 !important;
	padding:0;
}
#pagination {
	text-align:center;
}
.search-results h3 {
	color:#f9d41f;
}
.search-results h3 a {
	text-decoration:none;
	color:#f9d41f;
}
.search-results h3 a:hover {text-decoration:underline;}
.search-results {
	text-align:left;
	margin:30px 0 0;
}
.search-results .result-image {
	margin:0 20px 0 0;
}
#wrapper {
	min-height:320px;
}
.customer_address_table select {
	width:100%;
}
.shopify-cart .product-description a {
	color:#292929;
}
@media only screen and (max-width: 767px) {
	.customer_address_table td {
		width:100% !important;
		display:block;
		text-align:left !important;
		padding:5px 0 !important;
	}
  .shopify-cart .total {margin:0;}
.shopify-cart .btn {
	margin:0 0 20px;
	padding:11px 40px;
	width:100%;
	float:none;
}
  .shopify-cart {margin:0;}
  .shopify-cart table th,
.shopify-cart table td {
	display:block;
  overflow:hidden;
	width:100% !important;
}
  .shopify-cart .product-image {
	float:none;
	margin:0 0 10px;
	display:block;
    text-align:center;
}
  .shopify-cart .prd {
	text-align:center;
}
  .shopify-cart table th:first-child, .shopify-cart table td:first-child {
	width:100%;
	text-align:center;
}
  .shopify-cart .product-description {padding-bottom:20px;}
}
@media only screen and (max-width: 1023px) {

	.cart-box .cart-table th:first-child,
	.cart-box .cart-table td:first-child {
		width: 510px;
	}
    #wrapper .large_form,#wrapper  #customer,#wrapper  #recover-password{
      width: 100%;
      padding: 0 1px 0 0;
    }
    #wrapper #forgot_password a{
      width:30%;
    }
  #wrapper #customer_sidebar,
  #wrapper #customer_orders{
  	float: none;
	width: auto;
    padding:0 0 20px;
  }
  #wrapper #customer_orders{
    overflow: auto;
  }
  #wrapper #customer_orders table{
  	width: 100%;
    min-width:500px;
  }
  #wrapper .action_return a{
  	position: static;
  }
  #wrapper #admin_header .title{
  	padding: 0;
  }
}

.icon-tweet:before {
	content: "\e602";
}

.icon-search:before {
	content: "\e600";
}

.icon-cart:before {
	content: "\e601";
}
